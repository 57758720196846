'use client';

import { Suspense } from 'react';

import RandomLanguageCard from '@/components/LanguageCard/RandomLanguageCard';

import { siteDescription, siteName } from '@/config';
import { env } from '@/env';

export default function Home() {
  return (
    <div className='flex flex-col justify-center max-w-(--breakpoint-md) mx-auto pt-4 gap-4 h-screen'>
      <div className='flex-grow'>
        <div className='flex flex-col gap-1 py-16'>
          <h1 className='text-3xl font-bold text-center'>{siteName}</h1>
          <p className='text-center text-muted-foreground'>{siteDescription}</p>
        </div>
        {env.NEXT_PUBLIC_COMING_SOON ? (
          <div>Coming soon...</div>
        ) : (
          <Suspense>
            <RandomLanguageCard />
          </Suspense>
        )}
      </div>
      <footer className='text-sm text-muted-foreground text-center pb-4'>
        <p>
          Work in progress, by{' '}
          <a className='text-amber-500' href='https://bsky.app/profile/joostschuur.com'>
            Joost
          </a>
          . ❤ ️🐸
        </p>
      </footer>
    </div>
  );
}
